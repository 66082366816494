<template>
  <div>
    <ct-centered-spinner v-if="!loaded" height="16.25rem" />
    <div>
      <b-table :items="monitoringExclusions" :fields="fields">
        <template v-slot:head(identity_type)>
          Exclusion Type
        </template>
        <template v-slot:cell(identity_type)="data">
          <b-form-select 
            v-model="data.item.identity_type" 
            :options="valueOptions" 
            :disabled="disableEditMode" 
          />
        </template>
        <template v-slot:cell(actions)="data">
          <div class="exclusion-button">
            <b-dropdown text="Actions" variant="primary">
              <b-button 
                variant="primary" 
                size="sm" 
                class="edit-button" 
                @click="toggleEditMode()"
              >
                Edit
              </b-button>
              <b-button 
                variant="primary" 
                size="sm" 
                :disabled="disableEditMode" 
                class="save-button" 
                @click="saveChanges(data.item)"
              >
                Save
              </b-button>
              <b-button 
                variant="danger" 
                size="sm" 
                :disabled="disableEditMode" 
                class="delete-button" 
                @click="showModal(data.item)"
              >
                Delete
              </b-button>
            </b-dropdown>
          </div>
        </template>
        <template v-slot:cell(exclusions)="data">
          <b-form-tags 
            v-model="data.item.exclusions" 
            separator=","  
            size="lg"
            class="custom-form-tags"
            :disabled="disableEditMode" 
            placeholder="add exclusion"
          />
        </template>
      </b-table>
      <div class="add-exclusion-button">
        <b-button variant="success" @click="showAddDropdown">Add new Exclusion</b-button>
      </div>
      <div class="add-exclusion-form">
        <b-form v-if="showDropdown" @submit.prevent="addItem">
          <b-form-group label="Exclusions">
            <b-form-tags 
              v-model="newItem.exclusions" 
              separator="," 
              size="lg"
              class="custom-form-tags"
              placeholder="add exclusion"
            />
          </b-form-group>
          <b-form-group label="Value">
            <b-form-select 
              v-model="newItem.identity_type" 
              :options="valueOptionsNew" 
            />
          </b-form-group>
          <b-button type="submit" variant="primary">Add</b-button>
        </b-form>
      </div>
    </div>
    <b-modal ref="confirm-delete" hide-footer title="Are you sure you want to delete?">
      <b-button class="mt-3" variant="secondary" block @click="hideModal">Cancel</b-button>
      <b-button class="mt-2" variant="danger" block @click="deleteItem">Confirm</b-button>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
import { v4 as uuidv4 } from 'uuid'
import { makeToastMixin } from '@/mixins/makeToastMixin'

export default {
  name: "MonitoringExclusions",
  components: { CtCenteredSpinner },
  mixins: [makeToastMixin],
  data () {
    return {
      loaded: false,
      disableEditMode: true,
      fields: ['identity_type', 'exclusions', 'actions'],
      newItem: { exclusions: '', identity_type: '' },
      // Value options "value" maps directly to enums used in the Monitoring API code. So do not change these values unless the Enum itself has been updated to match or we will get bad data!
      valueOptions: [
        { value: 0, text: 'Trademark' },
        { value: 3, text: 'Domain' },
        { value: 4, text: 'Keyword' },
        { value: 5, text: 'Company' },
      ],
      valueOptionsNew: [],
      showDropdown: false,
      valueOptionsNewCopy: [],
      itemToDelete: {},
    }
  },
  computed: {
    ...mapGetters('monitoring', [
      'monitoringExclusions',
    ]),
    ...mapGetters('account', ['actualAccountID']),
  },
  async mounted() {
    this.loaded = false
    await this.fetchMonitoringExclusions(this.actualAccountID)
    // Make the copies for avoiding potential conflicts
    this.valueOptionsNew = this.valueOptions
    this.valueOptionsNewCopy= this.valueOptionsNew
    // Do not allow clients to create a new exclustion if one of the same type already exists. Force them to edit
    this.removeExistingExclusionTypes()
    this.loaded = true
  },
  methods: {
    ...mapActions('monitoring', [
      'fetchMonitoringExclusions',
      'updateMonitoringExclusions',
      'removeMonitoringExclusion',
    ]),
    showAddDropdown() {
      this.newItem = { exclusions: [], identity_type: 0 }
      // Always remove/clean the list when the dropdown is selected
      this.removeExistingExclusionTypes()
      this.showDropdown = !this.showDropdown
    },
    showModal(item) {
      this.itemToDelete = item
      this.$refs['confirm-delete'].show()
    },
    hideModal() {
      this.saveItem = {}
      this.$refs['confirm-delete'].hide()
    },
    async addItem() {
      try {
        this.newItem.account_id = this.actualAccountID
        this.newItem.id = uuidv4()
        await this.updateMonitoringExclusions(this.newItem)
        this.monitoringExclusions.push({ ...this.newItem })
        this.successToast('Success', 'Exclusion added')
      } catch (error) {
        this.errorToast('Error', "exclusion failed to add, please try again")
      } finally {
        this.showDropdown = false
      }
    },
    async saveChanges(item) {
      try {
        await this.updateMonitoringExclusions(item)
        this.successToast('Success', "exclusion(s) saved")
      } catch {
        this.errorToast('Error', "exclusion(s) failed to save, please try again")
      } 
    },
    async deleteItem() {
      let item = this.itemToDelete
      try {
        await this.removeMonitoringExclusion({ exclusionID: item.id, accountID: this.actualAccountID} )
        const index = this.monitoringExclusions.indexOf(item)
        if (index > -1) { 
          this.monitoringExclusions.splice(index, 1) 
        }

        this.successToast('Success', "exclusion deleted")
      } catch {
        this.errorToast('Error', "exclusion failed to delete, please try again")
      } finally {
        this.hideModal()
      }
    },
    toggleEditMode() {
      this.disableEditMode = !this.disableEditMode
    },
    itemAddedToast() {
      this.$bvToast.toast('Exclusion Added', {
        title: 'Success',
        autoHideDelay: 2000,
      })
    },
    removeExistingExclusionTypes() {
      this.valueOptionsNew = this.valueOptionsNewCopy.filter(e => {
        return !this.monitoringExclusions?.some(me => e.value === me.identity_type)
      })
    },
  },
}
</script>

<style>
.pad-right {
  padding-right: 0.2rem;
}

.exclusion-button {
  padding-top: .5rem;
}

.edit-button {
    margin-left: 1rem; 
}

.save-button {
    margin-left: 1rem; 
    margin-top: .5rem; 
}

.delete-button {
    margin-left: 1rem; 
    margin-top: .5rem; 
}

.add-exclusion-button {
  padding-left: 25rem; 
}

.add-exclusion-form {
  padding-top: 1rem;
}

.custom-form-tags .b-form-tags {
    font-size: 1.25rem; 
  }

.custom-form-tags .b-form-tag {
  padding: 0.5rem 1rem; 
  font-size: 1.25rem; 
}

</style>