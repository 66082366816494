var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "b-card-header",
            {
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.toggleExclusions.apply(null, arguments)
                },
              },
            },
            [
              _c("p", [
                _vm._v(
                  "Remove unwanted search results with the Exclusions feature"
                ),
              ]),
            ]
          ),
          _c(
            "b-collapse",
            {
              attrs: {
                visible: _vm.exclusionsExpanded,
                accordion: "my-accordion",
                role: "tabpanel",
              },
            },
            [_c("b-card-body", [_c("monitoring-exclusions")], 1)],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "b-card-header",
            {
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.toggleCancellation.apply(null, arguments)
                },
              },
            },
            [_c("p", [_vm._v("Cancel service")])]
          ),
          _c(
            "b-collapse",
            {
              attrs: {
                visible: _vm.cancellationExpanded,
                accordion: "my-accordion",
                role: "tabpanel",
              },
            },
            [
              _c(
                "b-card-body",
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "btn btn-danger",
                      on: {
                        click: function ($event) {
                          return _vm.cancelService()
                        },
                      },
                    },
                    [_c("strong", [_vm._v(" Cancel")])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("cancellation-modal", {
        attrs: { bus: _vm.bus },
        on: { "cancel-complete": _vm.handleCancel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }