var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.loaded
        ? _c("ct-centered-spinner", { attrs: { height: "16.25rem" } })
        : _vm._e(),
      _c(
        "div",
        [
          _c("b-table", {
            attrs: { items: _vm.monitoringExclusions, fields: _vm.fields },
            scopedSlots: _vm._u([
              {
                key: "head(identity_type)",
                fn: function () {
                  return [_vm._v("\n        Exclusion Type\n      ")]
                },
                proxy: true,
              },
              {
                key: "cell(identity_type)",
                fn: function (data) {
                  return [
                    _c("b-form-select", {
                      attrs: {
                        options: _vm.valueOptions,
                        disabled: _vm.disableEditMode,
                      },
                      model: {
                        value: data.item.identity_type,
                        callback: function ($$v) {
                          _vm.$set(data.item, "identity_type", $$v)
                        },
                        expression: "data.item.identity_type",
                      },
                    }),
                  ]
                },
              },
              {
                key: "cell(actions)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      { staticClass: "exclusion-button" },
                      [
                        _c(
                          "b-dropdown",
                          { attrs: { text: "Actions", variant: "primary" } },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "edit-button",
                                attrs: { variant: "primary", size: "sm" },
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleEditMode()
                                  },
                                },
                              },
                              [_vm._v("\n              Edit\n            ")]
                            ),
                            _c(
                              "b-button",
                              {
                                staticClass: "save-button",
                                attrs: {
                                  variant: "primary",
                                  size: "sm",
                                  disabled: _vm.disableEditMode,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.saveChanges(data.item)
                                  },
                                },
                              },
                              [_vm._v("\n              Save\n            ")]
                            ),
                            _c(
                              "b-button",
                              {
                                staticClass: "delete-button",
                                attrs: {
                                  variant: "danger",
                                  size: "sm",
                                  disabled: _vm.disableEditMode,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.showModal(data.item)
                                  },
                                },
                              },
                              [_vm._v("\n              Delete\n            ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "cell(exclusions)",
                fn: function (data) {
                  return [
                    _c("b-form-tags", {
                      staticClass: "custom-form-tags",
                      attrs: {
                        separator: ",",
                        size: "lg",
                        disabled: _vm.disableEditMode,
                        placeholder: "add exclusion",
                      },
                      model: {
                        value: data.item.exclusions,
                        callback: function ($$v) {
                          _vm.$set(data.item, "exclusions", $$v)
                        },
                        expression: "data.item.exclusions",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c(
            "div",
            { staticClass: "add-exclusion-button" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "success" },
                  on: { click: _vm.showAddDropdown },
                },
                [_vm._v("Add new Exclusion")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "add-exclusion-form" },
            [
              _vm.showDropdown
                ? _c(
                    "b-form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.addItem.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "Exclusions" } },
                        [
                          _c("b-form-tags", {
                            staticClass: "custom-form-tags",
                            attrs: {
                              separator: ",",
                              size: "lg",
                              placeholder: "add exclusion",
                            },
                            model: {
                              value: _vm.newItem.exclusions,
                              callback: function ($$v) {
                                _vm.$set(_vm.newItem, "exclusions", $$v)
                              },
                              expression: "newItem.exclusions",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        { attrs: { label: "Value" } },
                        [
                          _c("b-form-select", {
                            attrs: { options: _vm.valueOptionsNew },
                            model: {
                              value: _vm.newItem.identity_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.newItem, "identity_type", $$v)
                              },
                              expression: "newItem.identity_type",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-button",
                        { attrs: { type: "submit", variant: "primary" } },
                        [_vm._v("Add")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "confirm-delete",
          attrs: {
            "hide-footer": "",
            title: "Are you sure you want to delete?",
          },
        },
        [
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "secondary", block: "" },
              on: { click: _vm.hideModal },
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-2",
              attrs: { variant: "danger", block: "" },
              on: { click: _vm.deleteItem },
            },
            [_vm._v("Confirm")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }