<template>
  <b-container>
    <b-card no-body>
      <b-card-header @click.prevent="toggleExclusions">
        <p>Remove unwanted search results with the Exclusions feature</p>
      </b-card-header>
      <b-collapse :visible="exclusionsExpanded" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <monitoring-exclusions />
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body>
      <b-card-header @click.prevent="toggleCancellation">
        <p>Cancel service</p>
      </b-card-header>
      <b-collapse :visible="cancellationExpanded" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-button class="btn btn-danger" @click="cancelService()">
            <strong> Cancel</strong>
          </b-button>
        </b-card-body>
      </b-collapse>
    </b-card>

    <cancellation-modal :bus="bus" @cancel-complete="handleCancel" />
  </b-container>
</template>

<script>
import CancellationModal from '@/components/cancellation/CancellationModal.vue'
import MonitoringExclusions from '@/components/Monitoring/MonitoringExclusions.vue'
import { mapGetters } from 'vuex'
import Vue from 'vue'

export default {
  name: "MonitoringManagePage",
  components: {
    CancellationModal,
    MonitoringExclusions,
  },
  data() {
    return {
      bus: new Vue(),
      cancellationExpanded: false,
      exclusionsExpanded: true,
    }
  },
  computed: {
    ...mapGetters('monitoring', [
      'monitoringService',
    ]),
  },
  methods: {
    async cancelService() {
      this.bus.$emit('showCancelServices', [this.monitoringService])
    },
    async handleCancel() {
      await window.location.reload()
    },
    toggleExclusions() {
      this.exclusionsExpanded = !this.exclusionsExpanded
    },
    toggleCancellation() {
      this.cancellationExpanded = !this.cancellationExpanded
    },
  },
}
</script>

<style lang="scss" scoped>
  .expand-link {
    display: inline-block;
    color: rgb(0, 123, 255) !important;
    cursor: pointer;
  }

  .card {
    margin-bottom: 1rem;
    cursor: pointer;
  }

  .card p {
    margin: 0.5rem;
  }
</style>
